.hover-zoom {
  transition: all 0.6s ease-in-out;
  &:hover {
    transform: scale(1.2);
    color: #2e9d5e !important;
  }
}
.ruler {
  div {
    canvas {
      height: 45px !important;
    }
    div {
      top: -10px !important;
    }
  }
}

.btn_close_hover {
  transition: all 0.5s ease-in-out;
  &:hover {
    // background-color: rgb(203, 64, 64) !important;
    // border: 1px solid rgb(203, 64, 64) !important;
    // color: #fff !important;
    background-color: #ffc3c3 !important;
    border: 1px solid #ffc3c3 !important;
  }
}
.btn_crop_hover {
  transition: all 0.5s ease-in-out;
  &:hover {
    background-color: #d5f2e1 !important;
  }
}
.custom-file .dropzone {
  height: 150px;
  width: 160px;
  min-height: 120px;
  margin-right: 5px;
}

.vertical-middle {
  vertical-align: middle !important;
}
.pdfTable .react-bootstrap-table table {
  border-bottom: #fff !important;
}
.custom_up_btn {
  height: 35px;
  // margin: 0 12px;
  @media screen and (max-width: 1092) {
    margin: 0 !important;
  }
}
.myOrderTable tbody tr {
  line-height: 0 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
  display: none !important;
}
.custom_time_input {
  background: white;
  border-radius: 6px;
  border: 1px solid #ced4da;
}
.custom_time_input i {
  color: #2e9d5e !important;
  margin-left: 10px;
}
.custom_time_input .form-group input {
  border: none !important;
}
.revenue-avatar {
  height: 100px !important;
  width: 100px !important;
}
.date_wrapper .form-group {
  width: 160px !important;
}
.date_close {
  position: absolute;
  top: 3px;
  right: 25px;
  font-size: 15px !important;
  cursor: pointer;
  border: none !important;
  box-shadow: none !important;
}
.text_md_black {
  color: #494d51 !important;
}
.text_deals_green {
  color: #2e9d5e !important;
}
.text_deals_yellow {
  color: #f5d646 !important;
}
.bg_deals_green {
  color: #2e9d5e !important;
}
.bg-whiteSmook {
  background-color: #ffffff92 !important;
}
.bg-gray {
  background-color: #f1f1f1 !important;
}
.bg-lightGray {
  background-color: #f6f6f696 !important;
}
.bg-yellow {
  // background-color: #f5d646 !important;
  background-color: #fbf3c8 !important;
}
.bg-lightYellow {
  background-color: #fcf8e261 !important;
}
.bg-green {
  // background-color: #2e9d5e !important;
  background-color: #d5f2e1 !important;
}
.bg-lightGreen {
  // background-color: #d2f1dfcf !important;
  background-color: #f7fffacf !important;
}
.editor_input input {
  width: 80px !important;
}
.stock_input {
  width: 220px !important;
}
.stock_input div {
  // max-width: 220px !important;
}
.stock_input .form-group {
  flex: 1;
  max-width: 70px;
}
.new_custom_store_select .select-selection__control {
  border: none !important;
  box-shadow: none !important;
}
.stock_input .form-group input {
  width: 100% !important;
  max-width: 60px;
}
.custom_store {
  position: absolute;
  top: 90px;
  z-index: 99;
  width: 100%;
  // max-width: 100vw;
  @media screen and (max-width: 768px) {
    position: initial;
  }
}

.custom_store_child {
  min-width: 200px;
  max-width: 300px;
  margin: auto;
}
.unit_select {
  background: white;
  border: 1px solid #ced4da;
  border-radius: 6px;
  padding: 0 !important;
  position: relative;
  width: 60px;
  margin: auto !important;
}
.unit_select .fa-angle-down {
  position: absolute;
  right: 10px !important;
}
.value_input {
  width: 100px;
  margin: auto;
}

.custom_store .select_store_wrapp {
  background: white;
  border: 1px solid #ced4da;
  border-radius: 6px;
}
.custom_store .form-group,
.custom_store .form-group select .unit_select .form-group,
.unit_select .form-group select {
  border: none !important;
}
.store_icon {
  font-size: 20px;
  color: #2e9d5e;
}
.select_cu_store {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hover-gray:hover {
  background-color: #ededed;
}
.timePickerWrapper div input {
  width: 60px !important;
  // border-color: #cfcfd4;
  border-radius: 8px;
  padding: 4px 10px;
  border: 1px solid #cfcfd4;
}
.pikDateAndTime .react-datetime-picker__wrapper {
  border-radius: 10px;
  padding: 3px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-color: #cfcfd4;
}

.cursor-pointer {
  cursor: pointer !important;
}
.parent_dateRange .rdrDefinedRangesWrapper {
  display: none !important;
}
.parent_dateRange {
  position: relative;
}
.parent_dateRange .rdrDateRangePickerWrapper,
.parent_dateRange .rdrCalendarWrapper {
  width: 100% !important;
}
.parent_dateRange .rdrDateDisplayWrapper {
  background-color: transparent !important;
}
.parent_dateRange .rdrDateDisplay {
  // color: #cccccc !important;
  margin: 6px 0;
}
.parent_dateRange .rdrMonthAndYearWrapper {
  padding-top: 0 !important;
  height: 45px !important;
}
.parent_dateRange .rdrMonthAndYearWrapper,
.parent_dateRange .rdrMonths {
  display: none !important;
  position: absolute !important;
  background-color: #ffffff !important;
  z-index: 99;
  top: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 92%;
}
.parent_dateRange .rdrMonths {
  top: 100px;
}
.parent_dateRange:hover .rdrMonthAndYearWrapper {
  display: flex !important;
}
.parent_dateRange:hover .rdrMonths {
  display: flex !important;
}
.parent_dateRange:hover .card-body {
  min-height: 450px !important;
}
// .dailyProductParent .parent_dateRange .rdrMonths {
//   top: 140px !important;
// }
// .dailyProductParent .parent_dateRange .rdrMonthAndYearWrapper,
// .parent_dateRange .rdrMonths {
//   top: 90px !important;
// }

.custom_margin_top {
  margin-top: -20px;
  @media screen and (max-width: 1200px) {
    margin-top: 0;
  }
  @media screen and (max-width: 992px) {
    margin-top: -20px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
}
// .custom_store_select {
//   @media screen and (max-width: 768px) {
//     margin-top: 20px;
//   }
// }

.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}
.text-skyBule {
  color: #1e81b0 !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}
#area_table .areaBadge {
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
}
.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

.custom-dashed {
  border-right: dashed !important;
}

@media screen and (max-width: "768px") {
  .custom-dashed {
    border: none !important;
  }
}

.disabled:focus {
  box-shadow: none;
  border: none;
}
.custom-table-new .table th:last-child {
  text-align: end;
  padding-right: 2rem;
}
